import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ProductOverview } from './ProductOverview';
import { BodyFeatureCard } from '../Industry/Features/BodyFeatureCard';
import { BodyFeature } from '../Industry/Features/BodyFeature';
import { Testimonials } from '../Testimonials';
import { MobileFeatures } from '../Industry/Features/Mobile/MobileFeatures';

import { CustomerLogos } from '../CustomerLogos';
import { CtaCard } from '../General/CtaCard';

import { WaveUpSVG } from '../WaveSVGs/WaveUpSVG';

const useStyles = makeStyles((theme) => ({
	testimonialBackground: {
		backgroundSize: 'cover',
		height: '600px',
		width: '99vw',
		[theme.breakpoints.down('md')]: {
			height: '500px',
			width: '100vw',
		},
		[theme.breakpoints.down('sm')]: {
			height: '400px',
		},
	},
	testimonialContainer: {
		marginTop: '-35rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '-30rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-25rem',
		},
	},
	testimonialTitle: {
		color: theme.white,
		fontWeight: 700,
		padding: '2rem 0',
	},
}));

export const ProductFeatureBody = ({
	productFeature,
	iosLogo,
	androidLogo,
	qbLogo,
	zapierLogo,
	shopifyLogo,
}) => {
	const classes = useStyles();

	const formRef = useRef();
	const featureRef = useRef(null);

	//card array that gets modified on click. set brand new array to be manipulated on mount
	const [cardArray, setCardArray] = useState([]);

	useEffect(() => {
		setCardArray(productFeature.featureSection);
	}, []);

	const setToFirst = (selected) => {
		const filteredArray = cardArray.filter(
			(item) => item._key !== selected._key
		);
		setCardArray([selected, ...filteredArray]);
	};

	//handles scrolling to selected feature => currently pointed at the div above the cards on desktop view
	const handleSelectFeature = async (e, content) => {
		await setSelectedFeature(content._key);
		setToFirst(content);
		featureRef?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	//state for selected card for features
	const [selectedFeature, setSelectedFeature] = useState(null);

	useEffect(() => {
		setSelectedFeature(productFeature.featureSection[0]?._key);
	}, []);

	const med = useMediaQuery('(max-width: 960px)');
	//const lg = useMediaQuery('(max-width: 1280px)');

	const {
		_rawOverviewContent,
		overviewImage,
		featureSection,
		featureHeader,
		//accentColor,
		softwareFeatures,
		testimonial,
		customerLogos,
		customerLogosHeader,
		testimonialHeader,
		testimonialTitle,
		caseStudy,
		testimonialBackground,
	} = productFeature;

	const firstSet = cardArray.length === 4 ? 2 : 3;
	const lastSet =
		cardArray.length === 4 
    ? -2 
    : cardArray.length === 6 
    ? -3 
    : cardArray.length === 5
    ? -2
    : null;
    
	const longPage = featureSection.length > 2;

	return (
		<>
			<Container>
				{_rawOverviewContent ? (
					<ProductOverview
						overviewImage={overviewImage}
						overviewBody={_rawOverviewContent}
					/>
				) : null}
				{med ? (
					<MobileFeatures
						featureSection={featureSection}
						featureHeader={featureHeader}
						featureRef={featureRef}
						selectedFeature={selectedFeature}
						accentColor='#002D5C'
						iosLogo={iosLogo}
						androidLogo={androidLogo}
						qbLogo={qbLogo}
						featureListCta={softwareFeatures?.featureListCta}
						//setFeatureModalOpen={setFeatureModalOpen}
						noOverview={!_rawOverviewContent}
					/>
				) : (
					longPage && (
						<BodyFeatureCard
							featureHeader={featureHeader}
							featureSection={featureSection}
							selectedFeature={selectedFeature}
							handleClick={handleSelectFeature}
							featureRef={featureRef}
							featureListCta={softwareFeatures?.featureListCta}
							//setFeatureModalOpen={setFeatureModalOpen}
							accentColor='#002D5C'
							noOverview={!_rawOverviewContent}
						/>
					)
				)}
			</Container>

			<Container>
				{!med &&
					cardArray
						.slice(0, firstSet)
						.map((feature, index) => (
							<BodyFeature
								feature={feature}
								index={index}
								key={index}
								selectedFeature={selectedFeature}
								accentColor='#002D5C'
								iosLogo={iosLogo}
								androidLogo={androidLogo}
								qbLogo={qbLogo}
								setSelectedFeature={setSelectedFeature}
                cardArray={cardArray}
							/>
						))}
			</Container>

			<div
				className={classes.testimonialBackground}
				style={{
					backgroundImage: `url(${testimonialBackground?.asset.gatsbyImageData.images.fallback.src})`,
				}}
			/>
			<WaveUpSVG fill='#FFF' />
			<div className={classes.testimonialContainer}>
				<Testimonials
					header={testimonialTitle}
					testimonials={testimonial}
					formRef={formRef}
					background
				/>
			</div>
			<Container>
				<CustomerLogos
					customerLogosArray={customerLogos}
					customerLogosHeader={customerLogosHeader}
				/>
			</Container>
			{lastSet ? (
				<div className={classes.featureBackground}>
					<Container>
						{!med &&
							cardArray.slice(lastSet).map((feature, index) => (
								<BodyFeature
									feature={feature}
									index={index}
									key={index}
									selectedFeature={selectedFeature}
									accentColor='#002D5C'
									// iosLogo={iosLogo}
									// androidLogo={androidLogo}
									// qbLogo={qbLogo}
									setSelectedFeature={setSelectedFeature}
                  cardArray={cardArray}
								/>
							))}
					</Container>
				</div>
			) : null}
			{!!caseStudy ? (
				<Container>
					<CtaCard
						background={caseStudy.ctaBackground}
						header={caseStudy.ctaHeader}
						subheader={caseStudy.ctaSubheader}
						ctaText={caseStudy.ctaText}
						ctaLink={caseStudy.ctaLink}
						internalLink={caseStudy.internalLink}
					/>
				</Container>
			) : null}
		</>
	);
};
